.custom-selectize{
  background-color: unset;
  border-radius: unset;
  margin-top: 5px;

}
.selectizeHeader{
    height: 32px;
    background-color: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 1);
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
  }

  .selectizeHeader input{
    background-color: unset !important;
    width: 85%;
    border-radius: unset !important;
    border: none;
    padding: 0px 5px;
    box-sizing: border-box;
    outline:none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .selectizeHeader .selectize_arrow {
    pointer-events: none;
    position: absolute;
    bottom: 12px;
    right: 15px;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #fff transparent transparent transparent;
  }

  .selectizeHeader.disable {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .selectizeHeader.disable ::placeholder{
    opacity: 0.8;
  }

  .selectizeHeader.disable .selectize_arrow{
    display: none;
  }

  .dropDown {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0px 0px 15px 15px;
    max-height: 100px;
    display: none;
  }
  
  .custom-selectize ::-webkit-scrollbar{
    background-color:#1d1d1d;
    width: 8px;
    height: 8px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .custom-selectize ::-webkit-scrollbar-thumb{
    background-color:#5b5b5b;
    border-radius:5px;
  }

  .dropDown .listDropDown{
    width:100%;
  }

  .dropDown .listDropDown .item{
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    padding: 2px 5px;
    box-sizing: border-box;
    transition: .3s;
    cursor: pointer;
    width:100%;
  }

  .dropDown .listDropDown .item:hover{
    background-color: #121212;
    padding-left:7px;
    transition:.3s;
    
  }

  @media screen and (max-width: 768px) {
    .custom-selectize{
      width: 100%;
      margin-top:10px;
      margin-bottom: 0px;
    }

    .custom-selectize .selectizeHeader input{
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      width: 100%;
    }
  }