.mask {
    opacity: 0.6;
}
button[data-tour-elem="left-arrow"] {
    width: 20px;
}
button[data-tour-elem="right-arrow"] {
    width: 20px;
}
div[role="dialog"] {
    background-color: transparent;
    box-shadow: none;
}

div[role="dialog"] button{
    color:white;
}

div[role="dialog"] button:hover{
    color:#FFEA00;
}
@media screen and (min-width: 768px) {
    #clip-path :last-child{
        y:52;
    }

    #mask-main rect[fill="black"]{
        height: 42px;
    }

    #mask-main :nth-last-child(-n+4) {
        display: none;
    }

    #mask-main.chatbot-highlight rect[fill="black"]{
        height: 69px;
    }
}