.tour-ballon-container{
    width: 200px;
    height: 200px;
    background-image: url('../../assets/Balao03.png');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    padding: 25px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tour-ballon-container p{
    text-align: center;
    color: #000;
    font-size: 14px;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .tour-ballon-container{
        width: 160px;
        height: 160px;
    }
    .tour-ballon-container p{
        font-size: 12px;
    }
  }