.tourScreenContainer{
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.tourScreenContainer img{
    width: 200px;
}

@media screen and (max-width: 768px) {
    .tourScreenContainer img{
        width: 160px;
    }
  }